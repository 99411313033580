import * as constants from '@/utils/analytics/constants/validations/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as actions from '@/utils/analytics/constants/actions'
import * as components from '@/utils/analytics/constants/components'

export const BOM_VALIDATIONS_PAGE = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_PAGE,
    component: components.DROPDOWN
  })
})

export const BOM_VALIDATION_DELETE = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_VALIDATIONS_DELETED,
    action: actions.DELETED,
    component: components.BUTTON
  })
})

export const BOM_COLLECTION_SAVED = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_COLLECTIONS_SAVED,
    action: actions.SAVED,
    component: components.BUTTON
  })
})

export const BOM_WHY_EXPLANATION = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_WHY_EXPLAIN,
    component: components.BUTTON
  })
})

export const BOM_DROPDOWN_CHANGED = {
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_DROPDOWN_TYPE_CHANGED,
    action: actions.CHANGED,
    type: '',
    component: components.BUTTON
  })
}

export const BOM_CREATE = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_CREATED,
    action: actions.CREATED,
    component: components.BUTTON
  })
})

export const BOM_UPDATE_STATUS = Object.freeze({
  name: constants.BOM_SECTION,
  attributes: getAttributes({
    info: constants.BOM_UPDATED_STATUS,
    action: actions.UPDATED,
    component: components.BUTTON,
    productModelBusinessName: '',
    status: ''
  })
})

// TEST CASES

export const TEST_CASES_VALIDATIONS_PAGE = Object.freeze({
  name: constants.TEST_CASES_SECTION,
  attributes: getAttributes({
    info: constants.TEST_CASES_PAGE,
    component: components.DROPDOWN
  })
})

export const TEST_CASES_VALIDATIONS_PAGE_PIN_MODAL = Object.freeze({
  name: constants.TEST_CASES_SECTION,
  attributes: getAttributes({
    info: constants.TEST_CASES_PAGE_PIN_MODAL_OPENED,
    component: components.MODAL
  })
})

export const TEST_CASES_DELETE_VALIDATION = Object.freeze({
  name: constants.TEST_CASES_SECTION,
  attributes: getAttributes({
    info: constants.TEST_CASES_DELETED_VALIDATION,
    action: actions.DELETED,
    component: components.BUTTON
  })
})

export const TEST_CASES_WHY = Object.freeze({
  name: constants.TEST_CASES_SECTION,
  attributes: getAttributes({
    info: constants.TEST_CASES_WHY_BTN,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const TEST_CASES_CREATE = Object.freeze({
  name: constants.TEST_CASES_SECTION,
  attributes: getAttributes({
    info: constants.TEST_CASES_CREATED,
    action: actions.CREATED,
    component: components.BUTTON
  })
})
