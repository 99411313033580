export function extractDataRecursively(object, data) {
  return Object.entries(object)
    .filter(([key, value]) => data[key] || typeof value === 'object')
    .reduce((acc, [key, value]) => {
      if (typeof value === 'object') {
        const extractedData = extractDataRecursively(value, data)
        return { ...acc, ...extractedData }
      }

      acc[data[key]] = value
      return acc
    }, {})
}

export function mapDataBasedOnList(object, data) {
  return data.reduce((acc, keys) => {
    const result = extractDataRecursively(object, keys)
    return Object.keys(result).length ? acc.concat(result) : acc
  }, [])
}
