/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import * as constants from '@/utils/analytics/constants/constants'

export const ON_DEMAND = constants.ON_DEMAND_EXPORTS

// ON DEMAND EXPORTS
export const ON_DEMAND_EXPORTS_PAGE = `${ ON_DEMAND }_opened`
export const ON_DEMAND_EXPORTS_BOM_VAL_PAGE = `${ ON_DEMAND }_opened_bom_validations`
export const ON_DEMAND_EXPORTS_OPENED = `${ ON_DEMAND }_opened_user_export`
export const ON_DEMAND_EXPORTS_BOM_VAL_OPENED = `${ ON_DEMAND }_redirect_to_validations_page`
export const ON_DEMAND_EXPORTS_BOM_VAL_CREATED = `main_${ ON_DEMAND }_bom_validation_created`
export const ON_DEMAND_EXPORTS_TEST_CASES_VAL_CREATED = `main_${ ON_DEMAND }_test_case_validation_created`
export const ON_DEMAND_EXPORTS_UNUSABLE_CHOICE_VAL_CREATED = `main_${ ON_DEMAND }_unusable_choice_validation_created`
