// Helper function to record Amplify analytics
import { getBuiltAttributes } from '@/utils/analytics/utils/multi-attributes'
import { Analytics } from 'aws-amplify'
import getUserSession from '@/utils/getUserSession'

export default async function recordAnalytics(
  { name = '', attributes = {}, metrics = {} } = {},
  extraData = null
) {
  const { user } = await getUserSession()
  const attrs = { ...attributes, department: user?.department || '', userId: user?.username }

  let eventBody = { name, attributes: attrs, metrics }

  if (extraData) eventBody = getBuiltAttributes(extraData, { name, attributes: attrs, metrics })

  Analytics?.record(eventBody)
}
