import Notify from 'quasar/src/plugins/Notify.js';
import * as notificationTypes from '@/plugins/quasar/notifications/notificationTypes'

// Modifies notification types for Error message
const notificationTypesString = Object.values(notificationTypes).map(type => `"${ type }"`).join(', ')

// Shows notification to the user
export default function notify({
  title = '',
  content = '',
  actions = [],
  type = 'light',
  closeIcon = true,
  progress = true,
  position = 'top-right',
  timeout = 0
} = {},
simpleNotification = false) {
  let notifyMessage = content

  const disableTimeout = type === notificationTypes.NEGATIVE || type === notificationTypes.WARNING

  const notificationTimeout = progress && !disableTimeout
    ? parseInt(process.env.VUE_APP_NOTIFICATION_TIMEOUT_MS, 10) : timeout

  const closeAction = {
    class: 'mi-notification__close',
    icon: 'close',
    round: true,
    dense: true
  }

  if (type === notificationTypes.BASIC_INFO) {
    return Notify.create({
      message: notifyMessage,
      timeout: notificationTimeout,
      position,
      color: 'primary'
    })
  }

  if (type === notificationTypes.NEGATIVE) {
    const now = new Date()
    if (now) notifyMessage += `<br> <b>Error Time:</b> ${ now.toLocaleString() }`
  }

  if (!Object.values(notificationTypes).includes(type)) {
    throw new Error(`
      Specified type "${ type }" does not exist, please choose one of the following: ${ notificationTypesString }.
    `)
  }

  if (!Array.isArray(actions)) {
    throw new Error('Actions provided for the notification should be an array.')
  }

  if (simpleNotification) {
    return Notify.create({
      type,
      timeout: notificationTimeout,
      caption: notifyMessage,
      position
    })
  }

  return Notify.create({
    type,
    progress,
    timeout: notificationTimeout,
    actions: closeIcon ? [closeAction, ...actions] : actions,
    message: title,
    html: true,
    caption: notifyMessage,
    position
  })
}
