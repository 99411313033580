import { isAlreadyTracedSession } from '@/utils/userSession'

import * as analyticsConstants from './constants/constants'
import * as customSessionStorage from '../sessionStorage'

export default function setRecordAnalyticsTrace(
  data = null
) {
  if (!data
    || customSessionStorage.has(data, analyticsConstants.ANALYTICS_TRACE)
  ) return

  customSessionStorage.set(data, analyticsConstants.ANALYTICS_TRACE)
}

export function clearRecordAnalyticsTrace() {
  customSessionStorage.clearItem(analyticsConstants.ANALYTICS_TRACE)
}

export function isFlowCompletedPerSession(types = '') {
  const flow = customSessionStorage.extract(analyticsConstants.ANALYTICS_TRACE)
  return types.includes(flow) && !isAlreadyTracedSession(types)
}
