import * as analyticsConstants from '@/utils/analytics/constants/constants'

export const extract = key => (sessionStorage.getItem(key))

function buildContent(data = '', type = '') {
  const traceData = extract(type)
  return `${ traceData };${ data }`.replace(
    `${ analyticsConstants.NULL + analyticsConstants.TRACE_SPLIT }`,
    ''
  )
}

// javascript:S1788 - default parameters should be last
// Was not changed because of questions where this function is invoked
export function set(data = '', type) { // NOSONAR
  const content = buildContent(data, type)
  sessionStorage.setItem(type, content)
}

// javascript:S1788 - default parameters should be last
// Was not changed because of questions where this function is invoked
export function has(data = '', type) { // NOSONAR
  const items = extract(type)
  return items ? items.includes(data) : false
}

export function clearItem(type = '') {
  sessionStorage.removeItem(type)
}
