import * as constants from '@/utils/analytics/constants/product-model/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as actions from '@/utils/analytics/constants/actions'
import * as components from '@/utils/analytics/constants/components'

export const PROD_MODEL_VALIDATIONS = {
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: '',
    action: actions.SELECT,
    productModelBusinessName: '',
    component: components.BUTTON
  })
}

export const PROD_MODEL_CHANGED = Object.freeze({
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: constants.PRODUCT_MODEL_CHANGED,
    action: actions.CHANGED,
    component: components.BUTTON
  })
})

export const PROD_MODEL_LOADED = Object.freeze({
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: constants.PRODUCT_MODEL_LOADED,
    action: actions.LOADED,
    component: components.BUTTON
  })
})

export const PROD_MODEL_SETTINGS_OPENED = {
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: constants.PRODUCT_MODEL_OPENED_SETTINGS,
    action: actions.OPENED,
    productModelBusinessName: '',
    exportedAutoBom: '',
    component: components.BUTTON
  })
}

export const PROD_MODEL_EXPORTED_EXCEL = {
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: constants.PRODUCT_MODEL_EXPORTED_EXCEL,
    action: actions.EXPORTED,
    productModelBusinessName: '',
    component: components.BUTTON
  })
}

export const PROD_MODEL_SAVED_VALIDATION = {
  name: constants.PRODUCT_MODEL_SECTION,
  attributes: getAttributes({
    info: constants.PRODUCT_MODEL_SAVED_VALIDATION,
    action: actions.SAVED,
    productModelBusinessName: '',
    component: components.BUTTON
  })
}
