export default function getAttributes({
  info = '',
  action = '',
  productModelBusinessName = '',
  searchString = '',
  type = '',
  exportedAutoBom = '',
  component = '',
  timeSpent = '',
  status = ''
}) {
  return {
    info,
    action: action.replace(/[()]/g, ''),
    type,
    searchString,
    productModelBusinessName,
    userAgent: navigator.userAgent,
    exportedAutoBom,
    component,
    timeSpent,
    status
  }
}
