import * as constants from '@/utils/analytics/constants/variant-management/constants'
import * as actions from '@/utils/analytics/constants/actions'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as components from '@/utils/analytics/constants/components'

export const VM_OPENED = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_PAGE_OPEN,
    action: actions.OPENED
  })
})

export const SAVED_TABLES_OPENED = Object.freeze({
  name: constants.SAVED_TABLES_SECTION,
  attributes: getAttributes({
    info: constants.SAVED_TABLES_OPENED,
    action: actions.OPENED
  })
})

export const SAVED_TABLES_OPENED_FROM_SEARCH = Object.freeze({
  name: constants.SAVED_TABLES_SECTION,
  attributes: getAttributes({
    info: constants.SAVED_TABLES_OPENED_FROM_SEARCH,
    action: actions.OPENED
  })
})

export const SAVED_TABLES_DOWNLOAD_CALCULATION = Object.freeze({
  name: constants.SAVED_TABLES_SECTION,
  attributes: getAttributes({
    info: constants.SAVED_TABLES_DOWNLOAD_CALCULATION,
    component: components.BUTTON
  })
})

export const SAVED_TABLES_DELETE_COMBINATION = Object.freeze({
  name: constants.SAVED_TABLES_SECTION,
  attributes: getAttributes({
    info: constants.SAVED_TABLES_DELETE_COMBINATION,
    component: components.BUTTON
  })
})

export const SAVED_TABLES_EDIT_NAME_COMBINATION = Object.freeze({
  name: constants.SAVED_TABLES_SECTION,
  attributes: getAttributes({
    info: constants.SAVED_TABLES_EDIT_NAME_COMBINATION,
    component: components.BUTTON
  })
})

export const VM_SAVED_FOR_LATER_CALCULATION = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_SAVED_FOR_LATER,
    component: components.TOGGLE
  })
})

export const VM_SEARCH = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_SEARCH_PERFORMED,
    action: actions.SEARCH,
    component: components.INPUTS
  })
})

export const VM_RECENT_SEARCH = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_RECENT_SEARCH_PERFORMED,
    action: actions.SEARCH,
    searchString: '',
    component: components.INPUTS
  })
})

export const VM_CLEARED_COLLECTION = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COLLECTION_CLEARED,
    action: actions.CLEARED,
    type: '',
    component: components.BUTTON
  })
})

export const VM_SIDEBAR_COLLAPSED = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COLLAPSED_SIDEBAR,
    action: actions.COLLAPSED,
    component: components.SIDEBAR
  })
})

export const VM_ADDED_ALL_OPTIONS = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_ALL_OPTIONS_ADDED,
    action: actions.ADDED,
    component: components.CHECKBOX
  })
})

export const VM_COMPLETED_FLOW = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COMPLETE_FLOW
  })
})

export const VM_COMPLETE_FLOW_TIME_SPENT = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COMPLETE_FLOW_TIME
  })
})

export const VM_SHOW_COMBINATIONS = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_SHOW_COMBINATION,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const VM_AUTO_SWAP_COLUMN = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_AUTO_SWAP_COLUMNS,
    action: actions.AUTO_CLICKED,
    component: components.BUTTON
  })
})

export const VM_SWAP_COLUMN = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_SWAP_COLUMNS,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const VM_COMB_TABLE_EXPORT = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COMB_TABLE_EXPORTED,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const VM_SAVED_COLLECTION = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COLLECTION_SAVED,
    action: actions.SAVED,
    component: components.BUTTON
  })
})

export const VM_OPENED_COLLECTION = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_COLLECTION_OPENED,
    action: actions.OPENED,
    component: components.BUTTON
  })
})

export const VM_ON_DEMAND_EXPORT_SELECTED = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_ON_DEMAND_EXPORT_SELECTED,
    action: actions.SELECT,
    component: components.DROPDOWN
  })
})

export const VM_SKIP_WAITING = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_SKIP_WAITING,
    component: components.BUTTON
  })
})

export const SHOW_ME_LATER_TOGGLE_AND_SHOW_COMBINATIONS = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.SHOW_ME_LATER_TOGGLE_AND_SHOW_COMBINATIONS,
    action: actions.SELECT,
    component: components.TOGGLE
  })
})

export const VM_POSSIBLE_COMBINATIONS_SAVE_FOR_LATER = Object.freeze({
  name: constants.VM_SECTION,
  attributes: getAttributes({
    info: constants.VM_POSSIBLE_COMBINATIONS_SAVE_FOR_LATER,
    component: components.BUTTON
  })
})
