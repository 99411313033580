import * as constants from '@/utils/analytics/constants/global/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as actions from '@/utils/analytics/constants/actions'

export const NOT_FOUND = {
  name: constants.GLOBAL_SECTION,
  attributes: getAttributes({
    info: constants.NOT_FOUND_PREFIX,
    action: actions.SEARCH
  })
}
