<template>
  <filter-updates :whats-new-data="whatsNewData"></filter-updates>
  <update-card></update-card>
</template>

<script>
  import UpdateCard from '@/views/whatsNew/updateCard/UpdateCard.vue'
  import FilterUpdates from '@/views/whatsNew/FilterUpdates.vue'

  export default {
    name: 'Updates',
    components: { FilterUpdates, UpdateCard },
    props: {
      whatsNewData: {
        type: Array,
        required: true,
        default: () => []
      }
    }
  }
</script>
