export const SELECT = '( SELECT )'
export const UNSELECT = '( UNSELECT )'
export const DELETED = '( DELETED )'
export const SAVED = '( SAVED )'
export const EDITED = '( EDITED )'
export const UPDATED = '( UPDATED )'
export const CREATED = '( CREATED )'
export const AUTO_CLICKED = '( AUTO / CLICKED )'
export const EXPORTED = '( EXPORTED )'
export const FILLED_UP = '( FILLED UP )'
export const SEARCH = '( SEARCH )'
export const CLEARED = '( CLEARED )'
export const COLLAPSED = '( COLLAPSED )'
export const ADDED = '( ADDED )'
export const CHANGED = '( CHANGED )'
export const OPENED = '( OPENED )'
export const LOADED = '( LOADED )'
