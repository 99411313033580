/**
 * Formats date and applies timezone offset
 * @param value (ISO string date - YYYY-MM-DDTHH:MM:SS.MS)
 * @param includesTime
 * @returns {string} (Formatted date - DD/MM/YYYY HH:MM)
  */
export default function formatDate(value = '', { includesTime = true } = {}) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
    throw new Error('Provided date should have a valid ISO format.')
  }

  const datetime = new Date(value)
  const localDatetime = new Date(datetime.getTime() - (datetime.getTimezoneOffset() * 60 * 1000))

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includesTime && { hour: '2-digit', minute: '2-digit', hour12: false })
  }

  const formattedDate = localDatetime.toLocaleString('en-GB', options)

  return formattedDate
}

export function formatDateFilter(date1, date2) {
  const [day1, month1, year1, hour1, minute1] = date1.split(/[/ :]/)
  const [day2, month2, year2, hour2, minute2] = date2.split(/[/ :]/)

  const newDate1 = new Date(year1, month1 - 1, day1, hour1, minute1)
  const newDate2 = new Date(year2, month2 - 1, day2, hour2, minute2)

  const diff = newDate2 - newDate1

  if (diff > 0) {
    return 1
  }

  return (diff < 0) ? -1 : 0
}

export function getFirstDayOfYear() {
  const firstDayOfCurrentYear = new Date(Date.UTC(new Date().getFullYear(), 0, 1))
  return firstDayOfCurrentYear.toISOString()
}

export function getLastDayOfYear() {
  const today = new Date()
  const year = today.getFullYear()
  return new Date(year, 11, 31)
}

export function formatDateRequest(date) {
  const now = new Date(date)

  const year = now.getFullYear()
  const month = (now.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
  const day = now.getDate().toString().padStart(2, '0')
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const seconds = now.getSeconds().toString().padStart(2, '0')

  return `${ year }-${ month }-${ day }T${ hours }:${ minutes }:${ seconds }`
}

export function getRemainingDays(date) {
  const newDate = new Date(date)
  const currentDate = new Date()
  const timeDifference = newDate - currentDate

  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
}

const dateFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}

export const formatLocaleDate = date => date.toLocaleString(dateFormatOptions)
  .replace(/[/:,]/g, '-').replace(' ', 'T')

export const parseDateString = dateString => {
  const [day, month, year] = dateString.split('/').map(Number)
  return new Date(year, month - 1, day).getTime()
}

export const formatDateStrDdMmYyyy = dateStr => {
  if (!dateStr) return ''
  const [year, month, day] = dateStr.split('-')
  return `${ day }/${ month }/${ year }`
}

export const isValidDate = dateString => {
  const [day, month, year] = dateString.split('/').map(Number)
  const date = new Date(year, month - 1, day)
  return (
    date.getDate() === day
    && date.getMonth() === month - 1
    && date.getFullYear() === year
  )
}

export const formatMonthYear = dates => dates.map(({ month, year }) => {
  const date = new Date(year, month - 1)
  const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' })

  const formattedDate = formatter.format(date)

  return { month, year, formattedDate }
})

export const getUTCTodayDate = today => {
  const date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0, 0, 0
  )
  const utcDate = date.toISOString().substring(0, 19)
  return utcDate
}
