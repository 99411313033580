import * as constants from '@/utils/analytics/constants/home/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as actions from '@/utils/analytics/constants/actions'

export const HOME_PAGE_PI_PORTAL = Object.freeze({
  name: constants.HOME_PAGE_SECTION,
  attributes: getAttributes({
    info: constants.HOME_PAGE,
    action: actions.OPENED
  })
})

export const HOME_PAGE_SESSION = Object.freeze({
  name: constants.HOME_PAGE_SECTION,
  attributes: getAttributes({
    info: constants.HOME_PAGE_ONCE_PER_SESSION,
    action: actions.OPENED
  })
})

export const START_FLOW_VIA_HOMEPAGE = {
  name: constants.HOME_PAGE_SECTION,
  attributes: getAttributes({
    info: '',
    action: actions.SELECT
  })
}
