let timeContent = {}

export function storeTime(e) {
  if (!e.detail.endAt) {
    timeContent = { ...e.detail }
  }
  else {
    timeContent.endAt = e.detail.endAt
  }
}

export function cleanTime() {
  timeContent = {}
}

export function getTimeSpent() {
  const { startedAt, endAt } = timeContent

  if (startedAt !== undefined && endAt !== undefined) {
    const timeSpent = (endAt - startedAt) / 1000
    return Math.trunc(timeSpent)
  }

  return 0
}

export function getTimeContent() {
  return timeContent
}
