import * as constants from './constants'

const getTime = () => (
  new Date().getTime()
)

export default function emitFlowState(route, type) {
  if (!route) return

  if (constants.availableFlows.includes(route)) {
    const event = new CustomEvent(constants.flowEvent, {
      detail: {
        route
      }
    })

    if (type === 'start') event.detail.startedAt = getTime()
    if (type === 'end') event.detail.endAt = getTime()

    document.dispatchEvent(event)
  }
  else {
    document.dispatchEvent(new Event(constants.cleanFlowEvent))
  }
}
