<template>
  <div>
    <div class="no-updates-img">
      <img src="@/assets/images/checks-whats-new/no-updates.svg" alt="no-updates-icon" />
    </div>
    <div class="text-center">
      <span class="no-updates__text">
        There are no updates this month
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmptyWhatsNew'
  }
</script>

<style lang="scss" scoped>
  .no-updates-img {
    margin: 50px auto;
    width: 168px;
    height: 106px;
  }

  .no-updates__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
  }
</style>
