import * as constants from '@/utils/analytics/constants/constants'
import * as actions from '@/utils/analytics/constants/actions'

export const PAC_SECTION = constants.PAC

export const PAC_DELETED_CONFIGURATION = `${ PAC_SECTION }_deleted_configuration`

export const PAC_SAVED_CONFIGURATION = `${ PAC_SECTION }_saved_configuration`

export const PAC_SAVED_NEW_CONFIGURATION = `${ PAC_SECTION }_saved_new_configuration`

export const PAC_CREATED_CONFIGURATION = `main_${ PAC_SECTION }_configuration_created`

export const PAC_EDITED_CONFIGURATION = `${ PAC_SECTION }_edited_configuration`

export const PAC_UNSELECT_VALIDATIONS = `${ PAC_SECTION }_unselect_validation_choices`

export const PAC_INFINITE_SCROLL = `${ PAC_SECTION }_load_more_options`

export const PAC_INVALID_CHOICES_TAB = `${ PAC_SECTION }_invalid_choices_tab_opened`

export const PAC_FILTERED_CONFIGURATION = `${ PAC_SECTION }_filtered_options`

export const PAC_WINDCHILL_CONFIGURATION_EXPORT = `${ PAC_SECTION }_${ constants.WINDCHILL }_configuration`

export const PAC_EXPLANATION_REQUEST = `main_${ PAC_SECTION }_configuration_explanation`

export const PAC_VALIDATION_REQUEST = `main_${ PAC_SECTION }_validate`

export const PAC_CONFIGURATION_FILLED_UP = `${ PAC_SECTION }_fill_up_configuration`

export const PAC_GREEN_EXPORT = `${ PAC_SECTION }_${ actions.EXPORTED + constants.GREEN }_configuration`

export const PAC_YELLOW_EXPORT = `${ PAC_SECTION }_${ actions.EXPORTED + constants.YELLOW } _configuration`

export const PAC_WINDCHILL_VARIANT_EXPORTED = `${ actions.EXPORTED + constants.WINDCHILL }_variant_specification`
