import * as constants from '@/utils/analytics/constants/constants'

export const HOME_PAGE_SECTION = constants.HOME

export const HOME_PAGE = 'User just enter on'
  + ` ${ constants.PI_PORTAL }`

export const HOME_PAGE_ONCE_PER_SESSION = '(ONCE PER SESSION) User just enter on'
  + ` ${ constants.PI_PORTAL }`

export const OPEN_SPEC_PAGE_ON_CLICK = ' redirected to ROUTE_NAME'
