import * as constants from '@/utils/analytics/constants/constants'
import * as browsePortfolioConstants from '@/utils/analytics/constants/browse-portfolio/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as components from '@/utils/analytics/constants/components'

export const BROWSE_PORTFOLIO_PAGE_OPENED = Object.freeze({
  name: constants.BROWSE_PORTFOLIO,
  attributes: getAttributes({
    info: browsePortfolioConstants.BROWSE_PORTFOLIO_PAGE
  })
})

export const BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS = Object.freeze({
  name: constants.BROWSE_PORTFOLIO,
  attributes: getAttributes({
    info: browsePortfolioConstants.BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS,
    component: components.TAB
  })
})

export const BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS = Object.freeze({
  name: constants.BROWSE_PORTFOLIO,
  attributes: getAttributes({
    info: browsePortfolioConstants.BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS,
    component: components.TAB
  })
})
