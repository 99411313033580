import * as constants from '@/utils/analytics/constants/constants'
import * as actions from '@/utils/analytics/constants/actions'

export const VM_SECTION = constants.VM
export const SAVED_TABLES_SECTION = constants.SAVED_TABLES

export const VM_SAVED_FOR_LATER = `${ VM_SECTION }_saved_for_later`
export const VM_COMPLETE_FLOW = `${ VM_SECTION }_flow_completed`
export const VM_COMPLETE_FLOW_TIME = `${ VM_SECTION }_[Time spent]_user_completed_the_variant_management_flow`
export const VM_PAGE_OPEN = `${ VM_SECTION }_opened`
export const VM_SEARCH_PERFORMED = `${ VM_SECTION }_search_perfomed`
export const VM_RECENT_SEARCH_PERFORMED = `${ VM_SECTION }_recent_search`
export const VM_COLLECTION_CLEARED = `${ VM_SECTION }_collection`
export const VM_COLLAPSED_SIDEBAR = `${ VM_SECTION }_collapsed_sidebar`
export const VM_ALL_OPTIONS_ADDED = `${ VM_SECTION }_added_all_options`
export const VM_SHOW_COMBINATION = `main_${ VM_SECTION }_show_combinations`
export const VM_AUTO_SWAP_COLUMNS = `${ VM_SECTION }_swap_columns`
export const VM_SWAP_COLUMNS = `${ VM_SECTION }_swap_column`
export const VM_COMB_TABLE_EXPORTED = `${ VM_SECTION }_combinations_table_${ actions.EXPORTED }`
export const VM_COLLECTION_SAVED = `${ VM_SECTION }_saved_collection`
export const VM_COLLECTION_OPENED = `${ VM_SECTION }_opened_collection`
export const VM_ON_DEMAND_EXPORT_SELECTED = `${ VM_SECTION }_on_demand_export_selected`
export const SAVED_TABLES_OPENED = `${ SAVED_TABLES_SECTION }_opened`
export const SAVED_TABLES_DOWNLOAD_CALCULATION = `${ SAVED_TABLES_SECTION }_download_calculation`
export const SAVED_TABLES_DELETE_COMBINATION = `${ SAVED_TABLES_SECTION }_delete_combination`
export const SAVED_TABLES_EDIT_NAME_COMBINATION = `${ SAVED_TABLES_SECTION }_edit_name_combination`
export const VM_SKIP_WAITING = `${ VM_SECTION }_skip_waiting`
export const SAVED_TABLES_OPENED_FROM_SEARCH = `${ SAVED_TABLES_SECTION }_opened_from_search`
export const SHOW_ME_LATER_TOGGLE_AND_SHOW_COMBINATIONS = `${ VM_SECTION }_show_me_later_toggle_and_show_combinations`
export const VM_POSSIBLE_COMBINATIONS_SAVE_FOR_LATER = `${ VM_SECTION }_possible_combinations_save_for_later`
