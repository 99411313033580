import { base64EncodeUnicode } from '@/utils/base64'
import { ANALYTICS_TRACE_URL } from '@/utils/analytics/constants'
import * as customSessionStorage from './sessionStorage'

const USER_SESSION = 'user_session'

export function set(content = '') {
  const encodedContent = base64EncodeUnicode(content)
  customSessionStorage.set(encodedContent, USER_SESSION)
}

export function has(content = '') {
  const encodedContent = base64EncodeUnicode(content)
  return customSessionStorage.has(encodedContent, USER_SESSION)
}

export function get() {
  return customSessionStorage.extract(USER_SESSION)
}

export function isAlreadyTracedSession() {
  const flow = customSessionStorage.extract(ANALYTICS_TRACE_URL)
  const flowType = flow ? flow.split('/').pop() : flow
  if (has(flowType)) return true
  set(flowType)
  return false
}
