export const GLOBAL = 'global'
export const HOME = 'homepage'
export const PAC = 'pac'
export const BOM_VALIDATIONS = 'bom_validations'
export const TEST_CASES = 'test_cases'
export const VM = 'variant_management'
export const ON_DEMAND_EXPORTS = 'on_demand_exports'
export const PRODUCT_MODEL = 'product_model'
export const BROWSE_PORTFOLIO = 'browse_portfolio'
export const SAVED_TABLES = 'saved_tables'

export const NOT_FOUND_CONST = 'not_found'
export const NOT_AUTHORIZED = 'not_authorised'

export const ROUTE_NAME = 'route_name'
export const PI_PORTAL = 'pi_portal'
export const WINDCHILL = 'windchill'

export const SPACE = ' '

export const GREEN = ' _ green _'
export const YELLOW = ' _ yellow _ '

// Analytics trace
export const ANALYTICS_TRACE = 'aws-trace-session'
export const ANALYTICS_TRACE_URL = 'aws-amplify-analytics-prevUrl'
export const TRACE_SPLIT = ';'
export const NULL = 'null'
export const SHOW_COMBINATIONS = 'show_combinations'
export const VARIANT_MANAGEMENT_FLOW = `${ HOME + TRACE_SPLIT + VM + TRACE_SPLIT + SHOW_COMBINATIONS }`
export const VARIANT_MANAGEMENT_FLOW_DIRECT = `${ VM + TRACE_SPLIT + SHOW_COMBINATIONS }`
export const VARIANT_MANAGEMENT_COMPLETED_FLOW = [VARIANT_MANAGEMENT_FLOW_DIRECT, VARIANT_MANAGEMENT_FLOW]
