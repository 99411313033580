import { extractCognitoUser } from '@/utils/extractCognitoUser'

const cognitoTokenPayload = {
  username: 'k9390',
  email: '',
  privileges: 'LIST_USERS,PM_ACCESS_VSET,EXPORT_PM_VALIDATION,USE_ALIASES,LIST_PMS,LIST_COLLECTIONS,'
    + 'VARIANT_TREE_CALCULATIONS,SEARCH_COLLECTIONS,SEARCH_RULES,NO_ID_ACCESS,DOWNLOAD_PM_FILE,PM_ACCESS_PROD,'
    + 'DELETE_COLLECTIONS,CUD_USERS,PAC_ACCESS,LOAD_PM,PM_ACCESS_PSET,PM_ACCESS_QSET,VARIANT_TREE_RESULTS,'
    + 'VARIANT_TREE_EXPORT,PM_ACCESS_ASETKV,DELETE_RULES,SEARCH_PM_CONTENT,PM_ACCESS_QSETKV,PM_ACCESS_ASET,'
    + 'PM_ACCESS_ISET,LIST_ALIASES,USE_RULES,CREATE_COLLECTIONS,LIST_RULES,CREATE_ALIASES,CREATE_RULES,USE_COLLECTIONS,'
    + 'TEST_CASES_VALIDATION,ADMIN,START_PM_VALIDATION,LIST_VALIDATION_SETTINGS,PM_ACCESS_ESET,DELETE_ALIASES,'
    + 'CREATE_VALIDATION_SETTINGS,'
    + 'CREATE_NOT_USABLE_CHOICES_SETTINGS',
  'custom:department': 'MANINT'
}

// Gets user session data from Cognito.
export default async function getUserSession() {
  const user = extractCognitoUser(
    { ...cognitoTokenPayload, department: cognitoTokenPayload['custom:department'] }
  )
  const token = process.env.VUE_APP_TOKEN

  return { user, token }
}
