export const extractCognitoUser = ({
  username = '',
  email = '',
  privileges = [],
  department = ''
} = {}) => ({
  username,
  email,
  authorities: privileges,
  department
})
