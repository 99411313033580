import * as constants from '@/utils/analytics/constants/constants'

export const BOM_SECTION = constants.BOM_VALIDATIONS

export const TEST_CASES_SECTION = constants.TEST_CASES

// BOM

export const BOM_VALIDATIONS_DELETED = `${ BOM_SECTION }_deleted_validation`
export const BOM_COLLECTIONS_SAVED = `${ BOM_SECTION }_saved_collection`
export const BOM_WHY_EXPLAIN = `main_${ BOM_SECTION }_why_explanation`
export const BOM_DROPDOWN_TYPE_CHANGED = `${ BOM_SECTION }_change_select_type`
export const BOM_CREATED = `main_${ BOM_SECTION }_validation_created`
export const BOM_UPDATED_STATUS = `${ BOM_SECTION }_updated_validation_status`
export const BOM_PAGE = `${ BOM_SECTION }_opened`

// TEST CASES

export const TEST_CASES_DELETED_VALIDATION = `${ TEST_CASES_SECTION }_deleted_validation`
export const TEST_CASES_WHY_BTN = `main_${ TEST_CASES_SECTION }_why_explanation`
export const TEST_CASES_CREATED = `main_${ TEST_CASES_SECTION }_validation_created`
export const TEST_CASES_PAGE = `${ TEST_CASES_SECTION }_opened`
export const TEST_CASES_PAGE_PIN_MODAL_OPENED = `${ TEST_CASES_SECTION }_pin_modal_opened`
