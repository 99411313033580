import * as constants from '@/utils/analytics/constants/pac/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as actions from '@/utils/analytics/constants/actions'
import * as components from '@/utils/analytics/constants/components'

export const PAC_DELETED_CONFIG = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_DELETED_CONFIGURATION,
    action: actions.DELETED,
    component: components.BUTTON
  })
})

export const PAC_SAVED_CONFIG = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_SAVED_CONFIGURATION,
    action: actions.SAVED,
    component: components.BUTTON
  })
})

export const PAC_SAVED_NEW_CONFIG = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_SAVED_NEW_CONFIGURATION,
    action: actions.SAVED,
    component: components.BUTTON
  })
})

export const PAC_CREATED_CONFIG = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_CREATED_CONFIGURATION,
    action: actions.CREATED,
    type: '',
    component: components.BUTTON
  })
})

export const PAC_EDITED_CONFIG = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_EDITED_CONFIGURATION,
    action: actions.EDITED,
    component: components.BUTTON
  })
})

export const PAC_UNSELECT_VALIDATION = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_UNSELECT_VALIDATIONS,
    action: actions.UNSELECT,
    component: components.BUTTON
  })
})

export const PAC_OPTIONS_INFINITE_SCROLL = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_INFINITE_SCROLL,
    action: actions.SELECT,
    component: components.INFINITE_SCROLL
  })
})

export const PAC_CONFIG_FILLED_UP = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_CONFIGURATION_FILLED_UP,
    action: actions.FILLED_UP,
    type: '',
    component: components.INPUTS
  })
})

export const PAC_FILTERED_CONFIGURATION_OPTIONS = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_FILTERED_CONFIGURATION,
    action: actions.AUTO_CLICKED,
    type: '',
    component: components.FILTER
  })
})

export const PAC_WINDCHILL_CONFIGURATION_EXPORTED = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_WINDCHILL_CONFIGURATION_EXPORT,
    action: actions.EXPORTED,
    component: components.BUTTON
  })
})

export const PAC_EXPLANATION_REQUESTED = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_EXPLANATION_REQUEST,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const PAC_VALIDATION_REQUESTED = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_VALIDATION_REQUEST,
    action: actions.SELECT,
    component: components.BUTTON
  })
})

export const PAC_YELLOW = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_YELLOW_EXPORT,
    action: actions.EXPORTED,
    component: components.BUTTON
  })
})

export const PAC_GREEN = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_GREEN_EXPORT,
    action: actions.EXPORTED,
    component: components.BUTTON
  })
})

export const PAC_WINDCHILL_VARIANT_EXPORT = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_WINDCHILL_VARIANT_EXPORTED,
    action: actions.EXPORTED,
    component: components.BUTTON
  })
})

export const PAC_INVALID_CHOICES_TAB_OPENED = Object.freeze({
  name: constants.PAC_SECTION,
  attributes: getAttributes({
    info: constants.PAC_INVALID_CHOICES_TAB,
    action: actions.SELECT,
    component: components.TAB
  })
})
