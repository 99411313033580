import * as constants from '@/utils/analytics/constants/rule-validations/constants'
import getAttributes from '@/utils/analytics/constants/attributes'
import * as components from '@/utils/analytics/constants/components'
// ON DEMAND EXPORTS

export const ON_DEMAND_EXPORTS_VALIDATIONS_PAGE = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_PAGE,
    component: components.DROPDOWN
  })
})

export const ON_DEMAND_EXPORTS_BOM_PAGE = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_BOM_VAL_PAGE,
    component: components.BUTTON
  })
})

export const ON_DEMAND_EXPORTS_OPENED_ONE = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_OPENED,
    component: components.BUTTON
  })
})

export const ON_DEMAND_EXPORTS_OPENED_VAL_ONE = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_BOM_VAL_OPENED,
    component: components.BUTTON
  })
})

export const ON_DEMAND_EXPORTS_BOM_VALIDATION_CREATED = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_BOM_VAL_CREATED,
    component: components.BUTTON
  })
})

export const ON_DEMAND_EXPORTS_TEST_CASES_VALIDATION_CREATED = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_TEST_CASES_VAL_CREATED,
    component: components.BUTTON
  })
})

export const ON_DEMAND_EXPORTS_UNUSABLE_CHOICE_VALIDATION_CREATED = Object.freeze({
  name: constants.ON_DEMAND,
  attributes: getAttributes({
    info: constants.ON_DEMAND_EXPORTS_TEST_CASES_VAL_CREATED,
    component: components.BUTTON
  })
})
