import { Auth } from 'aws-amplify'
import getUserSessionLocal from '@/utils/getUserSessionLocal'
import getUserSessionCi from '@/utils/getUserSessionCi'
import * as userSession from '@/utils/userSession'
import { extractCognitoUser } from '@/utils/extractCognitoUser'

function storeUserSession() {
  if (userSession.get()) return
  userSession.set((+new Date()).toString(36))
}

// Gets user session data from Cognito.
export default async function getUserSession() {
  const currentToken = window.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_TOKEN)
  const e2eCiJobEnabled = !!process.env.VUE_APP_E2E_ENABLED
  const devEnv = process.env.NODE_ENV === 'development'

  storeUserSession()

  if (e2eCiJobEnabled && !currentToken) return getUserSessionCi()

  if (devEnv && !e2eCiJobEnabled) return getUserSessionLocal()

  const data = await Auth.currentSession()

  const cognitoIdToken = data?.getIdToken() || {}
  const user = extractCognitoUser(
    { ...cognitoIdToken.payload, department: cognitoIdToken.payload['custom:department'] }
  )
  const token = cognitoIdToken.getJwtToken?.() || ''

  return { user, token }
}
