<template>
  <div class="filter__wrapper">
    <mi-btn-dropdown
      :menu-offset="[0, 4]"
      class="filter__options-wrapper"
      auto-close
      :label="selectedFilter"
      dense
      flat
      icon-type
      dropdown-icon="caret-down"
    >
      <mi-list>
        <mi-list-item
          v-for="(filter) in filters"
          :key="filter.key"
          class="filter__options"
          clickable
          @click="filterWhatsNewData(filter.name)"
        >
          {{ filter.name }}
        </mi-list-item>
      </mi-list>
    </mi-btn-dropdown>
  </div>
</template>

<script>
  import MiList from '@/packages/@mi-library/MiList/MiList.vue'
  import MiListItem from '@/packages/@mi-library/MiList/MiListItem.vue'
  import { mapMutations } from 'vuex'
  import { SET_FILTERED_WHATS_NEW_DATA } from '@/store/modules/whatsNew/mutationTypes'
  import { PRODUCT_AREA_FILTER } from '@/constants/whatsNew'

  export default {
    name: 'FilterUpdates',
    components: { MiListItem, MiList },
    data: () => ({
      filters: PRODUCT_AREA_FILTER,
      selectedFilter: PRODUCT_AREA_FILTER[0].name
    }),
    created() {
      this.filterWhatsNewData(this.selectedFilter)
    },
    methods: {
      ...mapMutations('whatsNew', { SET_FILTERED_WHATS_NEW_DATA }),
      filterWhatsNewData(name) {
        this.SET_FILTERED_WHATS_NEW_DATA(name)
        this.selectedFilter = name
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter {
    &__wrapper {
      display: flex;
      justify-content: flex-end;
    }

    &__options-wrapper {
      font-size: 16px;
      font-weight: 700;
    }
  }
</style>
