import { Auth } from 'aws-amplify'
import { extractCognitoUser } from '@/utils/extractCognitoUser'

const userEmail = process.env.VUE_APP_E2E_USER_EMAIL
const userPassword = process.env.VUE_APP_E2E_USER_PASSWORD

export default async function getUserSessionCi() {
  try {
    const { signInUserSession } = await Auth.signIn(userEmail, userPassword)
    const { payload: cognitoTokenPayload } = signInUserSession.idToken
    const user = extractCognitoUser(
      { ...cognitoTokenPayload, department: cognitoTokenPayload['custom:department'] }
    )
    const token = signInUserSession.idToken.jwtToken
    return { user, token }
  }
  catch {
    return false
  }
}
