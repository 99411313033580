import store from '@/store'

// Check if user has each of provided privileges
export function hasAccess(privileges = []) {
  if (!Array.isArray(privileges)) {
    throw new Error('Authorities specified as a Router meta param should be an array.')
  }

  return privileges.every(privilege => store.state.user?.authorities?.includes(privilege))
}

// To be used in component templates as an inner function
export const canAccess = (...rest) => hasAccess([...rest])
