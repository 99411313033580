<template>
  <router-view></router-view>
</template>

<script>
  import setRecordAnalyticsTrace from '@/utils/analytics/recordAnalyticsTrace'
  import * as analyticsConstants from '@/utils/analytics/constants'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  export default {
    name: 'VariantManagement',
    mounted() {
      setRecordAnalyticsTrace(analyticsConstants.VM)
      recordAnalytics(analyticsConstants.VM_OPENED)
    }
  }
</script>
