<template>
  <mi-dialog
    key="checkWhatsNewModel-dialog"
    :model-value="!!whatsModalNewData"
    class="checks-whats-new-dialog"
    no-backdrop-dismiss
    custom-design-dialog
  >
    <div class="checks-whats-new">
      <!-- Header -->
      <div class="checks-whats-new-header">
        <div>
          <h1 class="checks-whats-new-header-title q-my-sm q-px-none text-uppercase">
            Check what´s new
          </h1>
        </div>
        <mi-btn
          v-close-popup
          icon="close"
          icon-type
          icon-size="18px"
          fab
          flat
          data-cy="checks-whats-new-close"
          @click="closeWhatsNewModal"
        ></mi-btn>
      </div>
      <!-- Content -->
      <div class="checks-whats-new-content">
        <template v-for="item in whatsModalNewData" :key="item.type">
          <h6
            v-if="!!item.topics.length"
            class="checks-whats-new-content--title text-uppercase"
          >
            {{ item.section }}
          </h6>
          <div v-if="!!item.topics.length">
            <h6 class="checks-whats-new-content--content-title text-uppercase">
              {{ item.title }}
              <mi-badge
                class="checks-whats-new-content--badge"
                :label="item.type"
                :title="item.type"
                rounded
              ></mi-badge>
            </h6>
            <ul>
              <template v-for="topic in item.topics" :key="topic">
                <li class="checks-whats-new-content--content-subtitle">
                  {{ topic }}
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </mi-dialog>
</template>

<script>
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import { NOTIFICATION_TYPES } from '@/constants/notifications'

  export default {
    name: 'CheckWhatsNewModal',
    components: { MiDialog },
    computed: {
      ...mapState('whatsNew', ['whatsModalNewData']),
      ...mapState('notifications', ['lastSeenTimestamp', 'newModalNotificationAvailable'])
    },
    async mounted() {
      await this.fetchWhatsNewContent()
    },
    methods: {
      ...mapActions('whatsNew', ['fetchModalWhatsNewData']),
      ...mapActions('notifications', ['fetchNotificationsState']),

      async fetchWhatsNewContent() {
        await this.fetchModalWhatsNewData(this.lastSeenTimestamp)
      },
      async closeWhatsNewModal() {
        await this.fetchNotificationsState({
          type: NOTIFICATION_TYPES.whatsNewModal,
          seen: true
        })
      }
    }
  }
</script>

<style lang="scss">

  ul {
    margin: 0;
    padding-left: 20px;
  }

  .checks-whats-new {
    display: flex;
    flex-direction: column;
  }

  .checks-whats-new-dialog .q-dialog__inner > .mi-card {
    width: 924px;
    max-width: 924px;
    min-width: 924px;
    position: relative;
  }

  .checks-whats-new-header {
    background-color: $mi-anthracite-50;
    height: 111px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .checks-whats-new-header-title {
    font-size: 36px;
    line-height: 56px;
    font-weight: 700;
    color: $mi-anthracite-800;
  }

  .checks-whats-new-content {
    width: 924px;
    max-width: 924px;
    min-width: 924px;
    padding: 16px 40px 64px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title {
      font-size: 18px;
      font-weight: 700;
      color: $mi-red-500;
      margin: 3% 0 2%;
    }

    &--title:first-of-type {
      margin: 0 0 2%;
    }

    &--body {
      margin: 0;
    }

    &--content-title {
      font-size: 18px;
      font-weight: 700;
      color: $mi-anthracite-800;
      margin: 0 0 1%;
      display: flex;
      gap: 8px;
    }

    &--badge {
      width: 50px;
      height: 22px;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 16px;
      padding: 1px 12px;
      background: $mi-anthracite-800;
      left: 0;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      display: flex;
      align-items: center;
    }

    &--content-title:before {
      content: "";
      display: block;
      width: 4px;
      height: 26px;
      background: $mi-red-500;
      left: 0;
    }

    &--content-subtitle {
      font-size: 14px;
      color: $mi-anthracite-800;
    }

    .check-whats-new-steps {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .check-whats-new-steps-wrapper {
      display: flex;
      gap: 33px;
    }

    .check-whats-new-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 301px;
      height: 206px;
    }

    .check-whats-new-imgs {
      height: 155px;
      width: 300px;
      border-radius: 2px;
    }

    .check-whats-new-description {
      line-height: 21px;
      font-size: 14px;

      &--header {
        font-weight: 400;
        color: $mi-anthracite-500;
      }

      &--text {
        color: $mi-anthracite-800;
      }
    }
  }
</style>
